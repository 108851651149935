<template>
  <v-app >
    <div>
      <v-app-bar
        v-if="!hiddenToolbar"
        fixed
        app
        color="primary"
        dense
        dark
        class="hidden-print-only"
      >
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="!showComputer"
        ></v-app-bar-nav-icon>
        <v-btn v-if="showComputer" icon text @click="$router.back()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title ><v-btn text @click="changeRouter('/home','Home')" style="text-transform: none">{{ clickPath }}</v-btn> </v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn text style="text-transform: none"  @click="changeRouter('/useros','OS')">Os</v-btn>
        <v-toolbar-items v-if="showComputer">
          <!-- <v-btn 
            text @click="changeRouter('/contactus')"  style="text-transform: none">Noti</v-btn> -->
            <v-menu offset-y left
          v-if="showComputer && (uiAccept.neworder || uiAccept.orderlist || uiAccept.orderdone|| uiAccept.orderfinish || uiAccept.orderadvance || uiAccept.toassign || uiAccept.riderlist || uiAccept.oslist || uiAccept.dailychecklist || uiAccept.dailyoutin || uiAccept.tracking) ">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" 
        :style="{
                  backgroundColor:
                  selectedComputer == 'order' ? '#000080' : 'transparent',
                }">
                <span class="ml-2 d-none d-sm-flex" style="text-transform: none"
                  >Order</span
                >
                <v-icon class="d-none d-sm-flex">mdi-menu-down-outline</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, index) in orders"
                  :key="index"
                  @click="changeRouter(item.value,item.clickPath)"
                  @focus="selectedComputer = 'order'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu> 
            <v-menu offset-y left
            v-if="showComputer && (uiAccept.payment || uiAccept.income || uiAccept.expense || uiAccept.finance) ">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" 
        :style="{
                  backgroundColor:
                  selectedComputer == 'pay' ? '#000080' : 'transparent',
                }">
                <span class="ml-2 d-none d-sm-flex" style="text-transform: none"
                  >Payments</span
                >
                <v-icon class="d-none d-sm-flex">mdi-menu-down-outline</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, index) in payments"
                  :key="index"
                  @click="changeRouter(item.value,item.clickPath)"
                  @focus="selectedComputer = 'pay'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
            <v-menu offset-y left
            v-if="showComputer && ( uiAccept.advancereport || uiAccept.riderreport || uiAccept.osdebitreport || uiAccept.debitcreditreport ||  uiAccept.paymentreport || uiAccept.incomereport || uiAccept.expensereport || uiAccept.financereport  || uiAccept.bankreport || uiAccept.profitandloss) ">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" 
        :style="{
                  backgroundColor:
                  selectedComputer == 'rep' ? '#000080' : 'transparent',
                }">
                <span class="ml-2 d-none d-sm-flex" style="text-transform: none"
                  >Reports</span
                >
                <v-icon class="d-none d-sm-flex">mdi-menu-down-outline</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, index) in reports"
                  :key="index"
                  @click="changeRouter(item.value,item.clickPath)"
                  @focus="selectedComputer = 'rep'"
                  
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
            <v-menu offset-y left
            v-if="showComputer && (uiAccept.township ||  uiAccept.branch || uiAccept.useraccount || uiAccept.companyprofile) ">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" 
        :style="{
                  backgroundColor:
                  selectedComputer == 'set' ? '#000080' : 'transparent',
                }">
                <span class="ml-2 d-none d-sm-flex" style="text-transform: none"
                  >Settings</span
                >
                <v-icon class="d-none d-sm-flex">mdi-menu-down-outline</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, index) in settings"
                  :key="index"
                  @click="changeRouter(item.value,item.clickPath)"
                  @focus="selectedComputer = 'set'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu> 
          
          <v-menu offset-y left>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <span class="ml-2 d-none d-sm-flex" style="text-transform: none"
                  >
                  {{loginUser.profileName }}
                  </span
                >
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, index) in profileList"
                  :key="index"
                  @click="changeRouter(item.value,item.clickPath)"
                  
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" fixed temporary>
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="/info/logo.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-title class="text-h6">
                {{loginUser.profileName }}
              </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        
        <v-list
          nav
          dense
        >
        <v-list-item 
        @focus="selectIndex = 'home'"
        :style="{
                  backgroundColor:
                    selectIndex == 'home' ? '#def3ff' : 'transparent',
                }"
                @click="changeRouter('/home','HOME')">
        <v-list-item-icon >
          <v-icon>mdi-home-floor-1</v-icon>
        </v-list-item-icon>

        <v-list-item-title >Home</v-list-item-title>
        </v-list-item>
        <v-list-group
        prepend-icon="mdi-folder-plus"
        v-if="!showComputer && (uiAccept.neworder || uiAccept.orderlist || uiAccept.orderdone || uiAccept.orderfinish || uiAccept.orderadvance || uiAccept.toassign || uiAccept.riderlist || uiAccept.oslist || uiAccept.dailychecklist || uiAccept.tracking) "
        
      >
        <template v-slot:activator>
          <v-list-item-title>Order Phone</v-list-item-title>
        </template>
          <v-list-item
            v-for="(item, i) in orders"
            :key="i"
            link
            @click="changeRouter(item.value,item.clickPath)"
            @focus="selectIndex = item.value"
            :style="{
                  backgroundColor:
                    selectIndex == item.value ? '#def3ff' : 'transparent',
                }"
          >
          <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
      </v-list-group>
      <v-list-group
        prepend-icon="mdi-credit-card-outline"
        v-if="!showComputer && (uiAccept.payment || uiAccept.income || uiAccept.expense || uiAccept.finance) "
      >
        <template v-slot:activator>
          <v-list-item-title>Payments</v-list-item-title>
        </template>
          <v-list-item
            v-for="(item, i) in payments"
            :key="i"
            link
            @click="changeRouter(item.value,item.clickPath)"
            @focus="selectIndex = item.value"
            :style="{
                  backgroundColor:
                    selectIndex == item.value ? '#def3ff' : 'transparent',
                }"
          >
          <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
      </v-list-group>
       <v-list-group
        prepend-icon="mdi-poll"
        v-if="!showComputer && (uiAccept.riderreport || uiAccept.osdebitreport || uiAccept.debitcreditreport ||  uiAccept.paymentreport || uiAccept.incomereport || uiAccept.expensereport || uiAccept.financereport || uiAccept.bankreport || uiAccept.profitandloss) ">
      >
        <template v-slot:activator>
          <v-list-item-title>Reports</v-list-item-title>
        </template>
          <v-list-item
            v-for="(item, i) in reports"
            :key="i"
            link
            @click="changeRouter(item.value,item.clickPath)"
            @focus="selectIndex = item.value"
            :style="{
                  backgroundColor:
                    selectIndex == item.value ? '#def3ff' : 'transparent',
                }"
          >
          <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
      </v-list-group>
       <v-list-group
        prepend-icon="mdi-power"
        v-if="!showComputer && (uiAccept.township ||  uiAccept.branch || uiAccept.useraccount || uiAccept.companyprofile) "
      >
        <template v-slot:activator>
          <v-list-item-title>Settings</v-list-item-title>
        </template>
          <v-list-item
            v-for="(item, i) in settings"
            :key="i"
            link
            @click="changeRouter(item.value,item.clickPath)"
            @focus="selectIndex = item.value"
            :style="{
                  backgroundColor:
                    selectIndex == item.value ? '#def3ff' : 'transparent',
                }"
          >
          <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
      </v-list-group>
      <v-list-group
        prepend-icon="mdi-face"
        
      >
        <template v-slot:activator>
          <v-list-item-title>My Profile</v-list-item-title>
        </template>
          <v-list-item
            v-for="(item, i) in profileList"
            :key="i"
            link
            @click="changeRouter(item.value,item.clickPath)"
            @focus="selectIndex = item.value"
            :style="{
                  backgroundColor:
                    selectIndex == item.value ? '#def3ff' : 'transparent',
                }"
          >
          <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
      </v-list-group>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn
              class="red--text"
              block
              rounded
              outlined
              @click="changeRouter('/logout')"
            >
              <v-icon>mdi-logout</v-icon>Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
      <!-- <v-content> -->
        <div class="mt-12 hidden-print-only"></div>
        <v-container fluid class="mt-0 pa-0">
        <v-progress-linear
          v-if="loading"
          fixed
          :indeterminate="loading"
          color="primary"
        ></v-progress-linear>
        <router-view :hideToolbar="hideToolbar" />
      </v-container>
      <!-- </v-content> -->
      <div>
        <v-dialog v-model="logoutModel" persistent max-width="350">
          <v-card>
            <v-card-title class="title">ထွက်ရန် သေချာပြီလား?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="logoutModel = false"
                >မလုပ်ပါ</v-btn
              >
              <v-btn color="primary" depressed @click="logout">ထွက်မည်</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div>
        <v-dialog v-model="passwordModel" persistent max-width="400">
          <v-form ref="form" v-model="valid">
            <v-card>
              <v-card-title class="headline"
                >စကား၀ှက် ပြောင်းတော့မှာလား?</v-card-title
              >
              <v-card-text class="pb-0">
                <v-text-field
                  :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showOld ? 'text' : 'password'"
                  @click:append="showOld = !showOld"
                  :rules="passwordOldRule"
                  v-model="password.old"
                  label="Old Password"
                  name="oldPassword"
                  placeholder=" "
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNew ? 'text' : 'password'"
                  @click:append="showNew = !showNew"
                  :rules="passwordNewRule"
                  v-model="password.new"
                  label="New Password"
                  name="newPassword"
                  placeholder=" "
                  outlined
                  dense
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="cancelChangePassword"
                  >မပြောင်းတော့ပါ</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="!valid"
                  depressed
                  @click="changePassword"
                  >ပြောင်းမည်</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from "../src/config";
import accountUserService from "./service/UserAccountService";
export default {
  mounted: function () {
    this.$router.afterEach((to, from) => {
      const isPublic = to.matched.some((record) => record.meta.public);
      this.eventCount++;
      if (!isPublic) {
        this.$_.delay(() => {
          if (this.eventCount == 1) {
            this.mini = true;
            this.eventCount = 0;
          } else {
            this.eventCount--;
          }
        }, 20000);
      }
    });
  },
  data() {
    return {
      hiddenToolbar: true,
      logoutModel: false,
      passwordModel: false,
      showOld: false,
      showNew: false,
      password: {},
      valid: true,
      passwordOldRule: [(v) => !!v || "Old Password is required"],
      passwordNewRule: [(v) => !!v || "New Password is required"],
      setting: {},
      limitDialog: false,
      loginUser: "",
      loadingCount: 0,
      loading: false,
      eventCount: 0,
      drawer: false,
      mini: true,
      uiAccept:{},
      profileList: [
        {
          title: "Change Password",
          icon: "mdi-lock",
          value: "/change_password",
        },
        {
          title: "Logout",
          icon: "mdi-logout",
          value: "/logout",
        },
      ],
      payments: [],
      reports: [],
      settings: [],
      orders: [],
      selectIndex:"home",
      selectedComputer:"home",
      clickPath:"HOME",
    };
  },
  props: {},
  mounted: function () {
    this.hiddenToolbar = false;
    this.loginMounted();
    axios.interceptors.request.use((config) => {
      this.loadingCount++;
      if (!this.loading) {
        this.loading = true;
      }
      return config;
    });
    axios.interceptors.response.use(
      (response) => {
        this.loadingCount--;
        if (this.loadingCount == 0 && this.loading) {
          this.loading = false;
        }
        return response;
      },
      (error) => {
        if (error.isAxiosError && error.message == "Network Error") {
          this.$swal("အင်တာနက် လိုင်းမကောင်းပါ", error.message, "error");
          this.loadingCount = 1;
          this.loading = false;
        }
        if (error.response.data != null && error.response.data.status == 401) {
          this.$swal(
            "Authentication Error",
            "ထွက်မည် ခလုတ်နှိပ်ပေးပါ",
            "error"
          );
          this.changeRouter("/logout");
          this.logout();
        }
        this.loadingCount--;
        if (this.loadingCount == 0 && this.loading) {
          this.loading = false;
        }
        return Promise.reject(error);
      }
    );
  },
  computed: {
    showComputer() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    isLogin: function () {
      let token = this.$store.state.userAccount.password;
      if (token == "") {
        return false;
      } else {
        axios.defaults.headers.common["Authorization"] = `deli${token}`;
        this.uiAcceptMethod();
        return true;
      }
    },
  },
  methods: {
    clickDrawer: function (item) {
      this.drawer = false;
      this.changeRouter(item.value,item.clickPath);
    },
    changePassword: function () {
      accountUserService
        .changePassword(this.password.old, this.password.new)
        .then((response) => {
          if (response > 0) {
            this.$swal("Successful", "Change Password", "success");
            this.logout();
          } else {
            this.$swal("unSuccessful", "Change Password", "error");
          }
          this.password = {
            old: "",
            new: "",
          };
        });
      this.passwordModel = false;
    },
    cancelChangePassword: function () {
      this.passwordModel = false;
      this.password = {};
    },
    hideToolbar: function (hide) {
      this.hiddenToolbar = hide;
      this.loginMounted();
    },
    changeRouter: function (path,clickPath) {
      this.clickPath = clickPath;
      if (path == "/logout") {
        this.logoutModel = true;
      } else if (path == "/change_password") {
        this.passwordModel = true;
      } else {
        this.$router
          .push({
            path: path,
          })
          .catch(() => {});
      }
    },
    logout: function () {
      this.$store.commit("updateUserAccount", {
        userName: "",
        password: "",
        role: "",
      });
      this.changeRouter("/login","HOME");
      this.logoutModel = false;
      this.hiddenToolbar = true;
    },
    loginMounted() {
      if (this.isLogin) {
        this.loginUser = this.$store.state.userAccount;
        this.hiddenToolbar = false;
      } else {
        this.hiddenToolbar = true;
        this.$router.push("/login").catch(() => {});
      }
    },
    uiAcceptMethod:function(){
      accountUserService
        .getUiByUserId()
        .then((response) => {
          this.uiAccept = Object.assign({},response);
          this.$store.commit("updateUiAccept", response);
        })
        .catch((err) => {
          this.$swal("Failed", err.response.data.message, "error");
        });
    },
  },
  watch: {
    //isLogin(value) {
      // if (value) {
      //   OneSignal.push(() => {
      //     accountUserService.getOnesignalAccount().then((data) => {
      //       OneSignal.setExternalUserId(data);
      //     });
      //   });
      // } else {
      //   OneSignal.push(function () {
      //     OneSignal.removeExternalUserId();
      //   });
      // }
    //},
     uiAccept:function(){
      this.payments.splice(0);
      if(this.uiAccept.payment){
          this.payments.push({
          title: "OS Finance",
          icon: "mdi-cash-100",
          value: "/payment",
          clickPath:"Payments > OS Finance",
        });
      }
      if(this.uiAccept.income){
          this.payments.push({
           title: "Income",
          icon: "mdi-cash-plus",
          value: "/income",
          clickPath:"Payments > Income",
        });
      }
      if(this.uiAccept.expense){
          this.payments.push({
           title: "Expense",
          icon: "mdi-bank-transfer-out",
          value: "/expense",
          clickPath:"Payments > Expense",
        });
      }
      if(this.uiAccept.finance){
          this.payments.push({
          title: "Office Finance",
          icon: "mdi-finance",
          value: "/finance",
          clickPath:"Payments > Office Finance",
        });
      }
      

      this.reports.splice(0);
      if(this.uiAccept.advancereport){
        this.reports.push({
          title: "Data Analytics (Delivery)",
          icon: "mdi-home-city",
          value: "/rtownship",
          clickPath:"Reports > Data Analytics (Delivery)",
        });
        this.reports.push({
          title: "Data Analytics (Return)",
          icon: "mdi-home-city",
          value: "/rosreturn",
          clickPath:"Reports > Data Analytics (Return)",
        });
      this.reports.push({
           title: "Advance Report",
          icon: "mdi-palette-advanced",
          value: "/advance",
          clickPath:"Reports > Advance Report",
        });
      }
      if(this.uiAccept.riderreport){
          this.reports.push({
           title: "Commission ",
          icon: "mdi-account-cash-outline",
          value: "/riderrpt",
          clickPath:"Reports > Commission",
        });
      }
       if(this.uiAccept.osdebitreport){
          this.reports.push({
           title: "Daily OS Debit",
          icon: "mdi-credit-card-settings-outline",
          value: "/osdebitrpt",
          clickPath:"Reports > Daily OS Debit",
        });
      }
       if(this.uiAccept.debitcreditreport){
          this.reports.push({
          title: "Debit/Credit",
          icon: "mdi-cash-multiple",
          value: "/debitcreditrpt",
          clickPath:"Reports > Debit/Credit",
        });
      }
       if(this.uiAccept.paymentreport){
          this.reports.push({
            title: "Daily Cash Report",
          icon: "mdi-cash-100",
          value: "/paymentrpt",
          clickPath:"Reports > Daily Cash Report",
        });
      }
       if(this.uiAccept.incomereport){
          this.reports.push({
          title: "Income",
          icon: "mdi-cash-plus",
          value: "/incomerpt",
          clickPath:"Reports > Income",
        });
      }
      if(this.uiAccept.expensereport){
          this.reports.push({
           title: "Expense",
          icon: "mdi-bank-transfer-out",
          value: "/expenserpt",
          clickPath:"Reports > Expense",
        });
      }
      if(this.uiAccept.financereport){
          this.reports.push({
           title: "Office Finance",
          icon: "mdi-finance",
          value: "/financerpt",
          clickPath:"Reports > Office Finance",
        });
      }
      if(this.uiAccept.bankreport){
          this.reports.push({
           title: "Bank",
          icon: "mdi-bank-check",
          value: "/bankrpt",
          clickPath:"Reports > Bank",
        });
      }
      if(this.uiAccept.profitandloss){
          this.reports.push({
          title: "Profit/Loss",
          icon: "mdi-credit-card-marker",
          value: "/profitlossrpt",
          clickPath:"Reports > Profit/Loss",
        });
      }


      this.settings.splice(0);
      if(this.uiAccept.township){
          this.settings.push({
           title: "Create Township",
          icon: "mdi-home-city",
          value: "/ts",
          clickPath:"Settings > Create Township",
        });
      }

      

      if(this.uiAccept.useraccount){
          this.settings.push({
          title: "Create User Account",
          icon: "mdi-account-multiple-plus",
          value: "/user",
          clickPath:"Settings > Create User Account",
        });
      }

      if(this.uiAccept.branch){
          this.settings.push({
          title: "Create Branch",
          icon: "mdi-office-building",
          value: "/branch",
          clickPath:"Settings > Create Branch",
        });
      }
      if(this.uiAccept.companyprofile){
          this.settings.push({
           title: "Create Company Profile",
          icon: "mdi-domain",
          value: "/company",
          clickPath:"Settings > Create Company Profile",
        });
      }
      if(this.$store.state.userAccount.role=='ADMIN'){
          this.settings.push({
          title: "Create UI Accept",
          icon: "mdi-access-point",
          value: "/ui",
          clickPath:"Settings > Create UI Accept",
        });
      }

      this.orders.splice(0);
      if(this.uiAccept.neworder){
          this.orders.push({
            title: "New Order",
          icon: "mdi-folder-plus",
          value: "/neworder",
          clickPath:"Orders > New Order",
        });
      }
       if(this.uiAccept.orderlist){
          this.orders.push({
            title: "Pickup",
          icon: "mdi-format-list-numbered",
          value: "/orderlist",
          clickPath:"Orders > Pickup",
        });
      }
      if(this.uiAccept.orderdone){
        this.orders.push({
            title: "Data Entry",
          icon: "mdi-format-list-numbered",
          value: "/orderlistdone",
          clickPath:"Orders > Data Entry",
        });
      }
      if(this.uiAccept.orderfinish){
        this.orders.push({
            title: "History",
          icon: "mdi-format-list-numbered",
          value: "/orderlistdonefinish",
          clickPath:"Orders > History",
        });
      }
      if(this.uiAccept.orderadvance){
          this.orders.push({
            title: "Advance",
          icon: "mdi-format-list-numbered",
          value: "/orderlistadvance",
          clickPath:"Orders > Advance",
        });
      }
       if(this.uiAccept.toassign){
          this.orders.push({
           title: "To Assign",
          icon: "mdi-account-multiple-check",
          value: "/toassign",
          clickPath:"Orders > To Assign",
        });
      }
      if(this.uiAccept.toassign){
          this.orders.push({
           title: "To Assign Return",
          icon: "mdi-clipboard-account-outline",
          value: "/toassignre",
          clickPath:"Orders > To Assign Return",
        });
      }
       if(this.uiAccept.riderlist){
          this.orders.push({
           title: "Rider's Balance",
          icon: "mdi-account-cash-outline",
          value: "/riderlist",
          clickPath:"Orders > Rider's Balance",
        });
      }
       if(this.uiAccept.oslist){
          this.orders.push({
            title: "Customer's Balance ",
          icon: "mdi-account-switch",
          value: "/oslist",
          clickPath:"Orders > Customer's Balance ",
        });
      }
       if(this.uiAccept.dailychecklist){
          this.orders.push({
            title: "Daily Check List",
          icon: "mdi-marker-check",
          value: "/dailychecklist",
          clickPath:"Orders > Daily Check List",
        });
      }
      
      if(this.uiAccept.dailyoutin){
      this.orders.push({
            title: "Daily Out/In(Branch)",
          icon: "mdi-source-branch-sync",
          value: "/outin",
          clickPath:"Orders > Daily Out/In(Branch)",
        });
      }
       if(this.uiAccept.tracking){
          this.orders.push({
            title: "Tracking",
          icon: "mdi-radar",
          value: "/trackinglist",
          clickPath:"Orders > Tracking",
        });
      }
      if(this.uiAccept.overduelist){
          this.orders.push({
            title: "Overdue Order List",
          icon: "mdi-lock-clock",
          value: "/overduelist",
          clickPath:"Orders > Overdue Order List",
        });
      }
      
     },
  },
  components: {},
  name: "App",
};
</script>
<!-- <style lang="scss">
@import "sass/main.scss";
</style> -->
<style scope>
html::-webkit-scrollbar {
  width: 0px;
}

html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.699);
  border-radius: 3px;
}

html::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #4caf50;
  padding: 3px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

div::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.699);

  border-radius: 3px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #4caf50;
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
}
</style>